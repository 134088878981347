import { useMutation, useQuery } from '@apollo/client'
import { Body, Button, DateTime, Price, Link, VerticalSpacer } from '@superhi/design'
import React, { useEffect, useState } from 'react'

import { API } from '../../../../api'
import { PageConfig } from '../../../../routing'
import Membership from '../View'
import Page from '../../../../components/Page'
import PageLayout from '../../../../components/PageLayout'
import { INTERVAL } from '../../utils'
import CancelPlan from '../CancelPlan'

import { PLAN, PLAN_PREVIEW } from './queries'
import { UPDATE_MEMBERSHIP } from './mutations'
import ROUTE, { PathArgs } from './route'
import RadioOptions from './components/RadioOptions'
import { CancelWrapper, Buttons } from './styles'

const OPTIONS = [API.StripePriceRecurringInterval.YEAR].map((interval) => ({
  display: INTERVAL(true)[interval],
  value: interval,
}))

const UpdatePlan: PageConfig<unknown, PathArgs> = () => {
  const { data } = useQuery<API.Membership_UpdatePlan>(PLAN)
  const plan = data?.session?.membership?.plan

  const [optionValue, setOptionValue] = useState<API.StripePriceRecurringInterval | undefined>(
    plan?.nextPrice?.recurring?.interval || plan?.currentPrice.recurring?.interval,
  )
  useEffect(() => {
    if (
      !optionValue &&
      (plan?.nextPrice?.recurring?.interval || plan?.currentPrice.recurring?.interval)
    ) {
      setOptionValue(
        plan?.nextPrice?.recurring?.interval || plan?.currentPrice?.recurring?.interval,
      )
    }
  }, [plan?.currentPrice.recurring?.interval, plan?.nextPrice?.recurring?.interval])

  const { data: previewData } = useQuery<
    API.Membership_UpdatePlanPreview,
    API.Membership_UpdatePlanPreviewVariables
  >(PLAN_PREVIEW, {
    variables: {
      interval: optionValue!,
    },
    skip: !optionValue,
  })

  const planPreview = previewData?.userMembershipPlanUpdatePreview

  const [updateMembership, updateMembershipResult] = useMutation<
    API.Membership_UpdatePlan_mutation,
    API.Membership_UpdatePlan_mutationVariables
  >(UPDATE_MEMBERSHIP, {
    variables: {
      interval: optionValue!,
    },
  })

  const handleClickUpdateMembership = React.useCallback(async () => {
    try {
      await updateMembership()
    } catch (e) {
      console.log('eeeee', e)
    }
  }, [updateMembership])

  const hasUpdated =
    updateMembershipResult.called &&
    !updateMembershipResult.loading &&
    !updateMembershipResult.error

  if (!plan) {
    return (
      <Page title="Update your plan">
        <PageLayout
          backUrl={Membership.route.path()}
          title="Update your plan"
          subtitle="Loading..."
        />
      </Page>
    )
  }

  const hasCancelled = !plan.nextPayment

  const membershipEndingText = !plan.nextPayment && (
    <>
      <Body level="3">
        Your {INTERVAL(true)[plan.currentPrice.recurring!.interval]} plan is currently set to end on{' '}
        <strong>
          <DateTime>{plan.renewalDate}</DateTime>
        </strong>
      </Body>
      <Body level="3">
        If you would like your plan to continue after this date, please choose &ldquo;Renew
        plan&rdquo;
      </Body>
    </>
  )
  const membershipRenewingText = plan.nextPrice &&
    plan.nextPayment &&
    plan.nextPrice.recurring?.interval === plan.currentPrice.recurring?.interval && (
      <>
        <Body level="3">
          Your {INTERVAL(true)[plan.currentPrice.recurring!.interval]} plan is currently set to
          renew on{' '}
          <strong>
            <DateTime>{plan.renewalDate}</DateTime>
          </strong>
        </Body>
      </>
    )
  const membershipRenewingAtDifferentIntervalText = plan.nextPrice &&
    plan.nextPayment &&
    plan.nextPrice.recurring?.interval !== plan.currentPrice.recurring?.interval && (
      <>
        <Body level="3">
          You are already scheduled to switch from{' '}
          {INTERVAL(true)[plan.currentPrice.recurring!.interval]} to{' '}
          <strong>{INTERVAL(true)[plan.nextPrice.recurring!.interval]}</strong> billing on{' '}
          <strong>
            <DateTime>{plan.renewalDate}</DateTime>
          </strong>
          .
        </Body>
      </>
    )

  const canSubmit =
    hasCancelled || !!plan.nextPrice?.recurring?.interval
      ? plan.nextPrice?.recurring?.interval !== optionValue
      : optionValue !== plan.currentPrice.recurring?.interval

  return (
    <Page title="Update your plan">
      <PageLayout
        backUrl={Membership.route.path()}
        title={hasUpdated ? 'Your plan has been updated!' : 'Update your plan'}
        footer={
          <CancelWrapper>
            {hasUpdated ? (
              <Button href={Membership.route.path()}>Done</Button>
            ) : canSubmit ? (
              <Button
                onClick={handleClickUpdateMembership}
                disabled={!canSubmit || updateMembershipResult.loading}
              >
                {updateMembershipResult.loading ? 'Loading...' : 'Renew plan'}
              </Button>
            ) : null}
          </CancelWrapper>
        }
      >
        {hasUpdated ? (
          <Body level="3" data-component-name="updated-confirmation-text">
            Your plan will continue on{' '}
            <strong>
              <DateTime>{plan.renewalDate}</DateTime>
            </strong>
            .
          </Body>
        ) : (
          <VerticalSpacer amount={{ small: 12, medium: 12, large: 12 }}>
            {membershipEndingText}
            {membershipRenewingText}
            {membershipRenewingAtDifferentIntervalText}

            {!planPreview ? (
              <Body level="3">Loading...</Body>
            ) : (
              <Body level="3">
                Your next payment will be for{' '}
                <strong>
                  <Price currencyCode={planPreview.nextPayment.currencyCode}>
                    {planPreview.nextPayment.value}
                  </Price>
                </strong>{' '}
                on{' '}
                <strong>
                  <DateTime>{plan.renewalDate}</DateTime>
                </strong>
                .
              </Body>
            )}
          </VerticalSpacer>
        )}
      </PageLayout>
      {!hasCancelled && !hasUpdated && (
        <CancelWrapper>
          <Body level="3">
            <Link href={CancelPlan.route.path()} color="GREY_70" hoverColor="GREY_90">
              Cancel plan
            </Link>
          </Body>
        </CancelWrapper>
      )}
    </Page>
  )
}

UpdatePlan.route = ROUTE

export default UpdatePlan
