import { useMutation, useQuery } from '@apollo/client'
import {
  Body,
  Button,
  DateTime,
  Buttons,
  Message,
  VerticalSpacer,
  TextButton,
} from '@superhi/design'
import React from 'react'

import { API } from '../../../../api'
import { PageConfig, Redirect } from '../../../../routing'
import Membership from '../View'
import Page from '../../../../components/Page'
import PageLayout from '../../../../components/PageLayout'
import { HelpScout } from '../../../../services'
import ErrorMessageHelpScout from '../../../../components/ErrorMessageHelpScout'
import { INTERVAL } from '../../utils'

import { PLAN } from './queries'
import { CANCEL_MEMBERSHIP } from './mutations'
import ROUTE, { PathArgs } from './route'

const CancelPlan: PageConfig<unknown, PathArgs> = () => {
  const { data } = useQuery<API.Membership_CancelPlan>(PLAN)

  const plan = data?.session?.membership?.plan

  const [cancelMembership, cancelMembershipResult] =
    useMutation<API.Membership_CancelPlan_mutation>(CANCEL_MEMBERSHIP)

  const handleClickCancelMembership = React.useCallback(async () => {
    try {
      await cancelMembership()
    } catch (e) {}
  }, [cancelMembership])

  const hasCancelled =
    cancelMembershipResult.called &&
    cancelMembershipResult.data?.membershipCancel?.membership?.plan?.nextPayment === null

  if (!plan) {
    return (
      <Page title="Cancel your plan">
        <PageLayout
          backUrl={Membership.route.path()}
          title="Cancel your plan"
          subtitle="Loading..."
        />
      </Page>
    )
  }

  if (!cancelMembershipResult.called && !plan.nextPayment) {
    return <Redirect to={Membership.route.path()} />
  }

  return (
    <Page title="Cancel your plan">
      <PageLayout
        backUrl={Membership.route.path()}
        title={hasCancelled ? 'Your plan has been cancelled!' : 'Cancel your plan'}
        footer={
          <>
            {cancelMembershipResult.error && <ErrorMessageHelpScout />}
            <Buttons align="center">
              {hasCancelled ? (
                <Button href={Membership.route.path()}>Done</Button>
              ) : (
                <Button
                  onClick={handleClickCancelMembership}
                  disabled={cancelMembershipResult.loading}
                >
                  {cancelMembershipResult.loading ? 'Loading...' : 'Cancel plan'}
                </Button>
              )}
            </Buttons>
          </>
        }
      >
        {hasCancelled ? (
          <Body level="3" data-component-name="cancel-confirmation-text">
            You still have access to your membership until{' '}
            <strong>
              <DateTime>{plan.renewalDate}</DateTime>
            </strong>
            .
          </Body>
        ) : (
          <VerticalSpacer>
            <Message label="What could be better?" color="YELLOW_20" level="2">
              We really want to hear about your experience. What&apos;s missing? What could be
              improved?{' '}
              <TextButton onClick={() => HelpScout.open()} color="BLUE_50" hoverColor="BLUE_60">
                Have a chat
              </TextButton>{' '}
              with one of our team and we&apos;ll try to find a solution before you leave.
            </Message>
            <div>
              <Body level="3">
                Please confirm that you&apos;d like to cancel your{' '}
                <strong>{plan.product.title}</strong> plan.
              </Body>
              <Body level="3">
                Cancelling this will mean your membership ends on{' '}
                <strong>
                  <DateTime>{plan.renewalDate}</DateTime>
                </strong>
                . You’ll still have access to your membership until then.
              </Body>
            </div>
          </VerticalSpacer>
        )}
      </PageLayout>
    </Page>
  )
}

CancelPlan.route = ROUTE

export default CancelPlan
